import React from 'react'
import './ChainDisplay.scss'
import parse from 'html-react-parser';


 type chainDisplayPropType = {
  chainData: ChainDataType
} 

const ChainDisplay = ( { chainData }: chainDisplayPropType ) => {

  const styledAndFormattedJson = (jsonString: string) => {
    const jsonObj = JSON.parse(jsonString);
    let formattedString = '';

    // Function to add indentation
    const addIndentation = (level: any) => '&nbsp;'.repeat(level * 4);

    // Iterate over each item in the "chain" array
    jsonObj.forEach((item: any, index: number, array: []) => {
      // Add indentation for the object level
      const objectIndentation = addIndentation(1);
      formattedString += objectIndentation;

      // Iterate over each key-value pair in the object
      Object.entries(item).forEach(([key, value]) => {
        formattedString += `<span class="key-in-chain">${key}:</span> <span class="value-in-chain">${value}</span><br>${objectIndentation}`;
      });

      // Remove the last added indentation for the object
      formattedString = formattedString.trimEnd();

      // Add an extra newline between printing objects, except after the last one
      if (index < array.length - 1) {
        formattedString += '<br>';
      }
    });

    return formattedString;
  };

  return (
    <div className="chain-display-component">
      {chainData && chainData.length > 0 ? parse(styledAndFormattedJson(JSON.stringify(chainData, null, 2))) : <div className='tds-u-ml2 tds-body-02'>No chain data</div>}
    </div>
    
  )
}

export default ChainDisplay