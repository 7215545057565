import { AnyObject } from "final-form";
import _ from "lodash";

export const newVariable: VariableDetail = {
  name: '',
  version: '1.0',
  description: '',
  status: '' as VariableStatusType,
  type: 'gauge',
  scope: '101',
  persist: undefined,
  boundaries: '',
  unit: '',
  minimum_cdc_version: '',
  current_status_name: '',
  rfms_name: '',
  value_names: [{ hexCode: "", name: "" }],
  chain: [{ type: "input", source: "" }] as ChainDataType,
  modifiedBy: '',
  should_remove_redundant_samples: undefined,
  max_samples: undefined,
  sample_interval_ms: undefined
};


export enum ChainItemTypeEnum {
  AbsoluteCount = "absolute_count",
  Accumulating = "accumulating",
  AccumulatingSelf = "accumulating_self",
  AccumulatingTime = "accumulating_time",
  Acking = "acking",
  Age = "age",
  Comparator = "comparator",
  Differential = "differential",
  Dyadic = "dyadic",
  Filter = "filter",
  Index = "index",
  Input = "input",
  LowPass = "lowpass",
  Map = "map",
  Not = "not",
  Polynomial = "polynomial",
  Signals = "Signals",
  TrailerIdentification = "trailer_identification",
  TypeCast = "type_cast",
  WindowedAverage = "windowed_average",
  Work = "work"
}

export const transformToUIFriendlyData = (variableData: VariableDetail) => {

  // Transform Value Names Object to Array
  let valueNamesArray: Array<ValueNamesObjectType> = [];
  const valueNames = variableData.value_names as AnyObject;
  if (variableData && valueNames) {
    Object.keys(valueNames).forEach((key: string) => {
      valueNamesArray.push({ hexCode: key, name: valueNames[key] })
    })
  }
  variableData.value_names = valueNamesArray;

  // Mark any Attributes retuned null to undefined as null is not undefined in the Eyes of Tegel TdsTextField
  // also update the chain item
  Object.keys(variableData).forEach((key) => {
    if (key === "chain") {
      variableData.chain?.forEach((element: ChainItemType) => {
        Object.keys(element).forEach((chainKey: string) => {
          if (element[chainKey] === null) {
            element[chainKey] = undefined
          }
          if (chainKey.toLowerCase() === 'persist') {
            element[chainKey] = element[chainKey] ? '1' : '0'
          }
        })
      });
    } else if (key.toLowerCase() === 'persist') {
      variableData[key] = variableData[key] ? '1' : '0'
    } else if (variableData[key] === null) {
      variableData[key] = undefined
    }
  });
  return variableData;
}


const preparingBoundaries = (input: string): number[] => {
  if (input === undefined) {
    return []
  }
  return input.split(',')
    .map(value => value.trim())
    .filter(value => value !== '')
    .map(value => parseFloat(value));
}

export const transformToAPIFriendlyData = (variableData: any) => {
  delete variableData["created"];
  if (variableData.value_names) {
    variableData.value_names = _.chain(variableData.value_names)
      .keyBy('hexCode')
      .mapValues('name')
      .value();
  }
  Object.keys(variableData).forEach((key) => {
    if (key === "chain") {
      variableData.chain?.forEach((element: ChainItemType) => {
        Object.keys(element).forEach((chainKey: string) => {
          if (chainKey.toLowerCase() === 'persist') {
            if (element[chainKey] !== undefined) {
              element[chainKey] = element[chainKey] === "1" ? true : false
            }
          }
        })
      });
    } else if (key.toLowerCase() === 'persist') {
      if (variableData[key] !== undefined) {
        variableData[key] = variableData[key] === '1' ? true : false
      }
    } else if (key.toLowerCase() === 'boundaries') {
      variableData[key] = preparingBoundaries(variableData.boundaries)
    } else if (['max_samples', 'sample_interval_ms'].includes(key.toLowerCase())) {
      variableData[key] = parseInt(variableData.max_samples, 10)
    }
  });
  return variableData;
}